import React, { ReactElement } from 'react';

interface Props {}

export default function newPage({}: Props): ReactElement {
  return (
    <main id="about" className="full-width nav-padding main-grid">
      <p>fdsafdsfa</p>
    </main>
  );
}
